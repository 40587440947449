/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Modal } from 'flowbite';
import moment from 'moment';
import { IBookingCreate } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-exceeding-km-limit',
  templateUrl: './modal-exceeding-km-limit.component.html',
  styleUrl: './modal-exceeding-km-limit.component.scss'
})
export class ModalExceedingKmLimitComponent implements OnChanges {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() title: string;
  @Input() text: string;
  @Input() img: string;
  @Input() booking: IBookingCreate | IBookingDetails;
  @Input() kmDone: number;
  @Input() kmLimit: number;
  @Input() buttonText: string;
  @Output() action = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  
  public images = images;
  public endOfMonthString = this.getEndOfMonth();

  constructor(public literalService: LiteralService, private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.kmDone = changes['kmDone'] && changes['kmDone'].currentValue;
    this.kmLimit = changes['kmLimit'] && changes['kmLimit'].currentValue;
    this.cdr.detectChanges();
  }

  public getEndOfMonth(): string {
    return moment().endOf('month').format('DD/MM');
  }

  onSubmit = () => {
    this.action.emit(this.booking);
    this.modal.hide();
  };
}
