import { Component, Input, isDevMode, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'flowbite';
import { JWTTokenService, NotificationService, StorageService, UsersService, UtilsService } from 'src/app/services';
import { LanguageService } from 'src/app/services/language/language.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { arrows, flags, images } from 'src/images';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  @Input() arrow = 'down';
  @Input() passwordModal: Modal;
  public arrowLanguage = 'down';
  public arrows = arrows;
  public flags = flags;
  public language = 'en';
  public open = false;
  public isDevMode = isDevMode();

  public images = images;

  constructor(
    renderer: Renderer2,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private languageService: LanguageService,
    public jwtTokenService: JWTTokenService,
    public literalService: LiteralService,
    public notificationService: NotificationService,
    public usersService: UsersService,
    public router: Router,
  ) {
    this.getLanguage();

    renderer.listen('window', 'click', (e: Event) => {
      const modal = document.getElementById('dropdown-user')!;
      const componentOpen = document.getElementById('openDropdownUser')!;
      const close = this.utilsService.closeCustomModals(e, modal, componentOpen);
      if (close) {
        document.getElementById('dropdown-user')?.classList.add('hidden');
        document.getElementById('dropdown-user')?.classList.remove('block');
        document.getElementById('dropdown-language')?.classList.add('hidden');
        document.getElementById('dropdown-language')?.classList.remove('block');
      }
    });
  }
  
  openChangePwdModal() {
    this.passwordModal.show();
  }

  public signOut() {
    this.router.navigate(['/'], {replaceUrl: true});
    this.storageService.removeToken();
    this.storageService.removePermissions();
  }

  openDropDownUser() {
    this.open = !this.open;
    if (document.getElementById('dropdown-user')?.classList.contains('hidden')) {
      document.getElementById('dropdown-user')?.classList.remove('hidden');
      document.getElementById('dropdown-user')?.classList.add('block');
    } else {
      document.getElementById('dropdown-user')?.classList.add('hidden');
      document.getElementById('dropdown-user')?.classList.remove('block');
    }
    document.getElementById('dropdown-user')!.style.position = 'fixed';
    document.getElementById('dropdown-user')!.style.inset = '0px auto auto auto';
    document.getElementById('dropdown-user')!.style.margin = '0px';
    document.getElementById('dropdown-user')!.style.top = '75px';
    document.getElementById('dropdown-user')!.style.right = '0';
    this.arrow = this.open ?
      document.getElementById('dropdown-user')?.classList.contains('hidden') ? 'up' : 'down' :
      !document.getElementById('dropdown-user')?.classList.contains('hidden') ? 'up' : 'down';
    if (!this.open) {
      this.arrow = 'down';
    }
  }

  public async getLanguage() {
    this.language = await this.storageService.getLanguage();
  }

  public async setLanguage(language: string) {
    document
      .getElementsByClassName('selected')[0]
      ?.classList.remove('selected');
    document.getElementById('dropdown-language')?.classList.add('hidden');
    await this.languageService.setLanguage(language);
    this.getLanguage();
  }

  openDropDownLanguages() {
    if (document.getElementById('dropdown-language')?.classList.contains('hidden')) {
      document.getElementById('dropdown-language')?.classList.remove('hidden');
      document.getElementById('dropdown-language')?.classList.add('block');
    } else {
      document.getElementById('dropdown-language')?.classList.add('hidden');
      document.getElementById('dropdown-language')?.classList.remove('block');
    }
    document.getElementById('dropdown-language')!.style.position = 'fixed';
    document.getElementById('dropdown-language')!.style.inset = '0px 0px auto auto';
    document.getElementById('dropdown-language')!.style.margin = '0px';
    document.getElementById('dropdown-language')!.style.top = '87px';
    document.getElementById('dropdown-language')!.style.right = '270px';
    this.arrowLanguage = document
      .getElementById('dropdown-language')
      ?.classList.contains('hidden')
      ? 'up'
      : 'down';
    document
      .getElementById('languages')
      ?.firstElementChild?.classList.add('selected');
  }
}
