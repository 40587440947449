import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Modal } from 'flowbite';
import { NotificationTypes } from 'src/app/models/enums';
import { JWTTokenService, NotificationService, UsersService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { arrows, images } from 'src/images';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() routeName = '';

  public hidden = true;
  public arrows = arrows;
  public images = images;
  public arrow = 'down';

  public password = '';
  public confirmPassword = '';
  public changingPassword = false;
  public changePasswordModalGeneral: Modal;
  public changePasswordModalGeneralShowed = false;

  private elementsId = [
    { id: 'adminsSectionList', event: 'mouseover', hidden: false },
    { id: 'adminsSectionList', event: 'mouseleave', hidden: true },
    { id: 'sidebar-submenu', event: 'mouseover', hidden: false },
    { id: 'sidebar-submenu', event: 'mouseleave', hidden: true },
    { id: 'adminsSubmenuSectionList', event: 'mouseover', hidden: false },
    { id: 'adminsSubmenuSectionList', event: 'mouseleave', hidden: true },
    { id: 'sidebar-submenu-admin', event: 'mouseover', hidden: false },
    { id: 'sidebar-submenu-admin', event: 'mouseleave', hidden: true },
  ];

  constructor(
    public literalService: LiteralService,
    private usersService: UsersService,
    private jwtTokenService: JWTTokenService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    this.elementsId.forEach((element) => {
      document
        .getElementById(element.id)
        ?.addEventListener(element.event, () => {
          this.hidden = element.hidden;
        });
    });
  }

  ngAfterViewInit(): void {
    this.initializeModals();
  }

  private async initializeModals() {
    setTimeout(() => {
      this.changePasswordModalGeneral = new Modal(
        document.getElementById('changePasswordModalGeneral'),
        {
          placement: 'center',
          closable: true,
        }
      );
    }, 100)
  }

  public handleClick = () => {
    document.getElementById('dropdown-user')?.classList.contains('hidden')
      ? 'up'
      : 'down';
    this.arrow = !document
      .getElementById('dropdown-user')
      ?.classList.contains('hidden')
      ? 'up'
      : 'down';
  };

  public async changePassword(password: string) {
    this.changingPassword = true;
    const userId = Number(this.jwtTokenService.getId());
    await this.usersService.changePassword(userId, password).then(() => {
      this.notificationService.image = images.user.password;
      this.notificationService.title = this.literalService.get(
        'users.actions.changePassword.passwordChangedSuccessTitle',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'users.actions.changePassword.passwordChangedSuccessText',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
    }, () => {
      this.notificationService.image = images.user.password;
      this.notificationService.title = this.literalService.get(
        'users.actions.changePassword.passwordChangedErrorTitle',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'users.actions.changePassword.passwordChangedErrorText',
        true,
      );
      this.notificationService.show(NotificationTypes.DANGER);
    });
    this.changingPassword = false;
  }
}
