import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { DarkModeComponent } from './components/dark-mode/dark-mode.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { UserDetailsComponent } from './components/users/user-details/user-details.component';
import { BaseHttpService } from './services/http/base.http.service';
import { DetailComponent } from './shared/detail/detail.component';
import { HeaderComponent } from './shared/header/header.component';
import { CalendarComponent } from './shared/input/calendar/calendar.component';
import { CheckboxComponent } from './shared/input/checkbox/checkbox.component';
import { InputSearchComponent } from './shared/input/input-search/input-search.component';
import { InputComponent } from './shared/input/input.component';
import { SelectComponent } from './shared/input/select/select.component';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { NotificationsComponent as NotificationsInputComponent } from './shared/input/notifications/notifications.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { TooltipModule } from './shared/tooltip/tooltip.module';
import { UserProfileBoxComponent } from './shared/user-profile/user-profile-box/user-profile-box.component';
import { UserProfileLanguageComponent } from './shared/user-profile/user-profile-language/user-profile-language.component';
import { UserProfileComponent } from './shared/user-profile/user-profile.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeCa from '@angular/common/locales/ca';
import localeEl from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEu from '@angular/common/locales/eu';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import { GoogleMapsModule } from '@angular/google-maps';
import { Select2Module } from 'ng-select2-component';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { BookingAvailabilitiesComponent } from './components/booking/booking-availabilities/booking-availabilities.component';
import { BookingDetailsComponent } from './components/booking/booking-details/booking-details.component';
import { BookingModalComponent } from './components/booking/booking-modal/booking-modal.component';
import { BookingSearchBarComponent } from './components/booking/booking-search-bar/booking-search-bar.component';
import { BookingFilterComponent } from './components/booking/booking-search-bar/filter/filter.component';
import { BookingSeatsComponent } from './components/booking/booking-seats/booking-seats.component';
import { BookingStopsComponent } from './components/booking/booking-stops/booking-stops.component';
import { MarkerOdComponent } from './components/markers/marker-od/marker-od.component';
import { MarkerStopDisabledComponent } from './components/markers/marker-stop-disabled/marker-stop-disabled.component';
import { MarkerStopComponent } from './components/markers/marker-stop/marker-stop.component';
import { FilterComponent } from './components/search-bar/filter/filter.component';
import { SortComponent } from './components/search-bar/sort/sort.component';
import { SeatsComponent } from './components/seats/seats.component';
import { ServiceTagComponent } from './components/service-tag/service-tag.component';
import { TripChangeAreaComponent } from './components/trips/trip-change-area/trip-change-area.component';
import { TripModalComponent } from './components/trips/trip-modal/trip-modal.component';
import { TripRowComponent } from './components/trips/trip-modal/trip-row/trip-row.component';
import { TripFilterComponent } from './components/trips/trip-search-bar/filter/filter.component';
import { TripSearchBarComponent } from './components/trips/trip-search-bar/trip-search-bar.component';
import { httpInterceptProviders } from './interceptors/http-interceptors';
import { DocumentTagComponent } from './shared/document-tag/document-tag.component';
import { EditableDetailComponent } from './shared/editable-detail/editable-detail.component';
import { InputPasswordComponent } from './shared/input/input-password/input-password.component';
import { MultiSelectComponent } from './shared/input/multi-select/multi-select.component';
import { SeatsInputComponent } from './shared/input/seats-input/seats-input.component';
import { modals } from './shared/modals';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { pages } from './pages';
import { initFlowbite } from 'flowbite';
import { inputs } from './shared/input';

registerLocaleData(localeEn, 'en-GB');
registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeCa, 'ca-ES');
registerLocaleData(localeEl, 'el-GR');
registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localePt, 'pt-PT');
registerLocaleData(localeEu, 'eu-ES');
registerLocaleData(localeFr, 'fr-FR');

export function tokenGetter() {
  return localStorage.getItem('token');
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter,
    allowedDomains: [environment.apiUrl],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    [...pages],
    [...modals],
    [...inputs],
    DarkModeComponent,
    NotificationsComponent,
    SidebarComponent,
    HeaderComponent,
    UserProfileComponent,
    UserProfileBoxComponent,
    UserProfileLanguageComponent,
    SearchBarComponent,
    UserDetailsComponent,
    DetailComponent,
    EditableDetailComponent,
    FilterComponent,
    SortComponent,
    TripModalComponent,
    TripChangeAreaComponent,
    ServiceTagComponent,
    TripRowComponent,
    MarkerOdComponent,
    MarkerStopComponent,
    MarkerStopDisabledComponent,
    BookingStopsComponent,
    SeatsComponent,
    BookingDetailsComponent,
    BookingSeatsComponent,
    BookingModalComponent,
    SeatsInputComponent,
    BookingSearchBarComponent,
    BookingAvailabilitiesComponent,
    TripSearchBarComponent,
    TripFilterComponent,
    BookingFilterComponent,
    DocumentTagComponent,
    PaginationComponent,
    NotificationsInputComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot(JWT_Module_Options),
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    Select2Module,
    LottieComponent,
    ClipboardModule,
    CommonModule
  ],
  providers: [
    BaseHttpService,
    httpInterceptProviders,
    provideHttpClient(withInterceptorsFromDi()),
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'ca-ES' },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: 'el-GR' },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: LOCALE_ID, useValue: 'eu-ES' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
