import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IBase, IBookingCreate } from 'src/app/models';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { objectToSnake } from 'ts-case-convert';
import { ApiRouterService } from '../http/api.router';
import { BaseHttpService } from '../http/base.http.service';
import { BookingApiRoutes } from './booking-api.routes';
import { IBookingUpdate } from 'src/app/models/booking/bookingUpdate';

@Injectable({
  providedIn: 'root',
})
export class BookingsHttpService extends BaseHttpService {
  private baseURL = `bookings`;

  constructor(
    @Inject(HttpClient) httpClient: HttpClient,
    private routerService: ApiRouterService,
  ) {
    super(httpClient);
  }

  public getBookings(size: number, page?: number) {
    let path = page ? `?page=${page}` : '';
    path +=
      path === '' ? (size ? `?size=${size}` : '') : size ? `&size=${size}` : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getBookingsFiltered(
    size: number,
    page: number,
    expeditionClients: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    cancelled?: boolean,
    searchValue?: string
  ) {
    let path = `/filter?page=${page}&size=${size}`;
    if (expeditionClients.length > 0) {
      const clientIds = expeditionClients.map(client => client.id).join(',');
      path += `&clients=${clientIds}`;
    }
    if (expeditionTowns.length > 0) {
      const townsIds = expeditionTowns.map(town => town.id).join(',');
      path += `&towns=${townsIds}`;
    }
    if (expeditionTypes.length > 0) {
      const types = expeditionTypes.map(types => types).join(',');
      path += `&types=${types}`;
    }
    if (expeditionStatus.length > 0) {
      const status = expeditionStatus.map(status => status).join(',');
      path += `&status=${status}`;
    }
    if (cancelled) {
      path += `&cancelled=${true}`;
    }
    path +=
      searchValue
        ? `&search=${searchValue}`
        : '';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public getBooking(bookingId: number, cancelled?: boolean) {
    let path = `/${bookingId}`;
    if (cancelled) {
      path += `?cancelled=${true}`;
    }
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }

  public validatePickUp(bookingId: number) {
    const path = `/${bookingId}/pick-up-time`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.updatePatch({}, url);
  }

  public validateDropOff(bookingId: number) {
    const path = `/${bookingId}/drop-off-time`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.updatePatch({}, url);
  }

  public createBooking(createBookingRequest: IBookingCreate) {
    const body = objectToSnake(
      { 
        availabilityId: createBookingRequest.availabilityId,
        targetUserId: createBookingRequest.targetUserId,
        serviceAvailabilityResponseId: createBookingRequest.serviceAvailabilityResponseId,
        serviceId: createBookingRequest.serviceId,
        originStopId: createBookingRequest.originStopId,
        destinationStops: createBookingRequest.destinationStops,
        tripId: createBookingRequest.tripId,
      }
    );
    const url = this.routerService.mount(`${this.baseURL}`);
    return this.create(body, url);
  }

  public multipleBooking(createBookingsRequest: IBookingCreate[]) {
    const body = createBookingsRequest.map((booking) => {
      return objectToSnake({
        availabilityId: booking.availabilityId,
        targetUserId: booking.targetUserId,
        serviceAvailabilityResponseId: booking.serviceAvailabilityResponseId,
        serviceId: booking.serviceId,
        inStop: booking.originStopId,
        seats: booking.destinationStops,
        tripId: booking.tripId,
      })
    });
    const path = `/${BookingApiRoutes.MULTIPLE}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.create(body, url);
  }


  
  public updateBooking(updateBookingRequest: IBookingUpdate) {
    const body = objectToSnake(
      { 
        availabilityId: updateBookingRequest.availabilityId,
        targetUserId: updateBookingRequest.targetUserId,
        loggedUserId: updateBookingRequest.loggedUserId,
        bookingId: updateBookingRequest.bookingId,
        tripId: updateBookingRequest.tripId,
        serviceId: updateBookingRequest.serviceId,
        originStop: updateBookingRequest.originStop,
        destinationStop: updateBookingRequest.destinationStop,
        seats: updateBookingRequest.seats,
        prmSeats: updateBookingRequest.prmSeats
      }
    );
    const url = this.routerService.mount(`${this.baseURL}`);
    return this.update(body, url);
  }

  public deleteBooking(bookingId: number, comment: string) {
    const path = `/${bookingId}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.delete(url, { id: bookingId, comment: comment });
  }

  public updateMissed(bookingId: number) {
    const path = `/${bookingId}/${BookingApiRoutes.MISSED}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.updatePatch({}, url);
  }

  public isExceedingKmLimit(userId: number, inStopId: number, outStopId: number, serviceId: number, nBookings: number) {
    const userIdParam = `userId=${userId}`;
    const inStopIdParam = `inStopId=${inStopId}`;
    const outStopIdParam = `outStopId=${outStopId}`;
    const serviceIdParam = `serviceId=${serviceId}`;
    const nBookingsParam = `nBookings=${nBookings}`;
    const path = `/exceeds-km-limit?${inStopIdParam}&${outStopIdParam}&${serviceIdParam}&${userIdParam}&${nBookingsParam}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.read(url);
  }
  
  public updateKms(bookingId: number, kms: number) {
    let path = `/${bookingId}/${BookingApiRoutes.KMS}`;
    path +=
      kms
        ? `?kms=${kms}`
        : '?kms=0';
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.updatePatch({}, url);
  }

  public updateTicketPaidDateTime(bookingId: number, paid: boolean) {
    let path = `/${bookingId}/${BookingApiRoutes.PAID_TICEKT}?paid=${paid}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.updatePatch({}, url);
  }

  public updateTicketValidatedDateTime(bookingId: number, validated: boolean) {
    let path = `/${bookingId}/${BookingApiRoutes.VALIDATE_TICEKT}?validated=${validated}`;
    const url = this.routerService.mount(`${this.baseURL}${path}`);
    return this.updatePatch({}, url);
  }

  public updateNotified(bookingId: number, isNotified: boolean) {
    const path = `/${bookingId}/${BookingApiRoutes.NOTIFIED}?notified=`;
    const url = this.routerService.mount(`${this.baseURL}${path}${isNotified}`);
    return this.updatePatch({}, url);
  }
}
