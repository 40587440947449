import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRole } from 'src/app/models/enums';
import { IUserPermission } from 'src/app/models/userPermission';
import { StorageService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() setRouteName = new EventEmitter<string>(true);
  @Input() routeName = '';

  public images = images;
  public hidden = true;
  public hiddenAdmin = true;
  public role = UserRole.ADMIN;

  public showSidebarTimeout: any = null;

  public page = '';

  constructor(
    public literalService: LiteralService,
    public storageService: StorageService,
  ) {}

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    const sidebarSubmenu = document.getElementById('sidebar-submenu') || null;
    const sidebarSubmenuAdmin =
      document.getElementById('sidebar-submenu-admin') || null;

    this.role = await this.storageService.getRole();

    // document.getElementById("adminsSectionList")?.addEventListener("mouseover", () => {
    //   this.hidden = false
    //   sidebarSubmenu && (sidebarSubmenu.style.setProperty('display', 'block', 'important'))
    // });
    const elements = Array.from(
      document.getElementsByClassName('sidebar-item-list'),
    );
    elements.forEach((element: Element) => {
      element.addEventListener('mouseover', () => {
        if (!this.showSidebarTimeout) {
          this.showSidebarTimeout = setTimeout(() => {
            if (this.hidden && this.showSidebarTimeout) {
              this.hidden = false;
              sidebarSubmenu && sidebarSubmenu.style.setProperty('display', 'block', 'important');
              this.showSidebarTimeout = null;
            }
          }, 500);
        }
      });
      element.addEventListener('mouseleave', () => {
        this.showSidebarTimeout = null;
      });
    });

    // document.getElementById("adminsSectionList")?.addEventListener("mouseleave", () => {
    //   if (!document.getElementById("sidebar-submenu")?.matches(':hover')) {
    //     this.hidden = true
    //     setTimeout(() => {
    //       sidebarSubmenu && (sidebarSubmenu.style.setProperty('display', 'none', 'important'))
    //     }, 200)
    //   }
    // });

    document.addEventListener('mouseleave', () => {
      if (!this.hidden) {
        this.hidden = true;
        sidebarSubmenu && sidebarSubmenu.style.setProperty('display', 'none', 'important');
        this.showSidebarTimeout = null;
      }
    })

    document
      .getElementById('sidebar-submenu')
      ?.addEventListener('mouseover', () => {
        this.hidden = false;
        document
          .getElementById('adminsSectionList')
          ?.classList.add('submenu-hovered');
        sidebarSubmenu &&
          sidebarSubmenu.style.setProperty('display', 'block', 'important');
      });

    document
      .getElementById('sidebar-submenu')
      ?.addEventListener('mouseleave', () => {
        if (
          !document.getElementById('adminsSectionList')?.matches(':hover') &&
          !document.getElementById('sidebar-submenu-admin')?.matches(':hover')
        ) {
          this.hidden = true;
          setTimeout(() => {
            sidebarSubmenu &&
              sidebarSubmenu.style.setProperty('display', 'none', 'important');
          }, 200);
          this.showSidebarTimeout = null;
        }
        document
          .getElementById('adminsSectionList')
          ?.classList.remove('submenu-hovered');
      });

    document
      .getElementById('adminsSubmenuSectionList')
      ?.addEventListener('mouseover', () => {
        this.hidden = false;
        this.hiddenAdmin = false;
        sidebarSubmenuAdmin &&
          sidebarSubmenuAdmin.style.setProperty(
            'display',
            'block',
            'important',
          );
      });

    document
      .getElementById('adminsSubmenuSectionList')
      ?.addEventListener('mouseleave', () => {
        // this.hidden = true
        this.hiddenAdmin = true;
        sidebarSubmenuAdmin &&
          sidebarSubmenuAdmin.style.setProperty('display', 'none', 'important');
      });

    document
      .getElementById('sidebar-submenu-admin')
      ?.addEventListener('mouseover', () => {
        this.hidden = false;
        this.hiddenAdmin = false;
        sidebarSubmenuAdmin &&
          sidebarSubmenuAdmin.style.setProperty(
            'display',
            'block',
            'important',
          );
        document
          .getElementById('adminsSectionList')
          ?.classList.add('submenu-hovered');
        document
          .getElementById('adminsSubmenuSectionList')
          ?.classList.add('submenu-admin-hovered');
      });

    document
      .getElementById('sidebar-submenu-admin')
      ?.addEventListener('mouseleave', () => {
        this.hidden = true;
        this.hiddenAdmin = true;
        sidebarSubmenuAdmin &&
          sidebarSubmenuAdmin.style.setProperty('display', 'none', 'important');
        document
          .getElementById('adminsSectionList')
          ?.classList.remove('submenu-hovered');
        document
          .getElementById('adminsSubmenuSectionList')
          ?.classList.remove('submenu-admin-hovered');
      });
      const storedPermissions = await this.storageService.getPermissions();
      let permissionsArray: IUserPermission[] = [];
      if (storedPermissions) {
        permissionsArray = JSON.parse(storedPermissions);
      }
      const entitySet = new Set(["USER", "EXPEDITION", "TOWN", "VEHICLE", "SERVICE", "RESERVATION", "MESSAGE", "SUGGESTION", "FARE", "HOLIDAY", "DASHBOARD"]);
      for (let i = 0; i < permissionsArray.length; i++) {
        const permission = permissionsArray[i];
          if (entitySet.has(permission.entityType)) {
              entitySet.delete(permission.entityType);
          }
      }
      const entityArray = Array.from(entitySet);
      for (let j = 0; j < entityArray.length; j++) {
        const button = document.getElementById(entityArray[j].toLowerCase() + '-tab');
          if (button != null) {
              button.style.display = "none";
          }
          const subtab = document.getElementById(entityArray[j].toLowerCase() + '-subtab');
          if (subtab != null) {
              subtab.style.display = "none";
          }
      }
  }

  public setRouteNameSelected(routeName: string) {
    this.setRouteName.emit(routeName);
  }

  getUrl = () => {
    if (environment.location === 'tst') {
      return 'https://tst.nemi.mobi/backoffice/';
    } else if (environment.location === 'sta') {
      return 'https://sta.nemi.mobi/backoffice/';
    } else if (environment.location === 'demo') {
      return 'https://demo.nemi.mobi/backoffice/';
    } else if (environment.location === 'prod') {
      return 'https://nemi.mobi/backoffice/';
    } else if (environment.location === 'dev') {
      return 'http://localhost:8080/backoffice/';
    }
    return 'https://nemi.mobi/backoffice/';
  };

  getDashboardUrl = () => {
    if (environment.location === 'tst') {
      return 'https://dashboard-tst.nemi.mobi/';
    }  else if (environment.location === 'demo') {
      return 'https://dashboard-dmo.nemi.mobi/';
    } else if (environment.location === 'sta') {
      return 'https://dashboard-sta.nemi.mobi/';
    } else if (environment.location === 'prod') {
      return 'https://dashboard.nemi.mobi/';
    }
    return 'https://dashboard.nemi.mobi/';
  };
}
