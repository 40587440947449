import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { INotification } from 'src/app/models';
import { NotificationTypes } from 'src/app/models/enums';
import { AuthService, NotificationService, RoleService, StorageService, UsersService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { initialNotificationState } from 'src/app/store/initialisation/notification';
import { images } from 'src/images';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  public notification: INotification = initialNotificationState;
  public images = images;

  public username = '';
  public password = '';

  public doingLogin = false;

  public loadingLogin: AnimationOptions = {
    path: '/assets/animations/loading-login-green.json'
  };

  constructor(
    public literalService: LiteralService,
    public authService: AuthService,
    private storageService: StorageService,
    public router: Router,
    private notificationService: NotificationService,
    private roleService: RoleService,
    public usersService: UsersService
  ) {
    if (this.authService.isAuthenticated()) {
      this.router.navigate([localStorage.getItem('route') || 'users']);
    }
  }

  setPassword = (event: any) => {
    this.password = event.value;
  };

  public async onSubmit() {
    this.doingLogin = true;
    await this.authService.login(this.username, this.password).then(() => { }, () => { });
    const token = await this.storageService.getToken();
    if (token) {
      await this.roleService.updateUserPermissions();
      const town = await this.usersService.getLoggedUserTown();
      if (town) this.storageService.setArea(JSON.stringify([town]));
      const route = localStorage.getItem('route');
      if (route) this.router.navigate([route]);
      else this.router.navigate(['users']);
    } else {
      this.notificationService.image = images.notification.error;
      this.notificationService.title = 'login.error.title';
      this.notificationService.message = 'login.error.text';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.DANGER);
    }
    this.doingLogin = false;
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1.5);
  }
}
