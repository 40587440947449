import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IBase } from 'src/app/models';
import { InputType } from 'src/app/models/enums';
import { UtilsService } from 'src/app/services';
import { images } from 'src/images';
import { TooltipPosition } from '../../tooltip/tooltip-position';
import { LiteralService } from 'src/app/services/literal/literal.service';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() elements?: any[] = [];
  @Input() values?: any[];
  @Input() required?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() width = 'auto';
  @Input() hasErrors?: boolean = false;
  @Input() error?: string = '';
  @Input() formDesign = true;
  @Input() dropdownParent?: string;
  @Input() showDropDown?: boolean;
  @Output() closeEventEmitter = new EventEmitter();
  @Output() openEventEmitter = new EventEmitter();
  @Output() onBlurEventEmitter = new EventEmitter();

  public isHidden: boolean = true;
  public searchableText: string = '';

  public images = images;
  public InputType = InputType;
  public TooltipPosition = TooltipPosition;

  public textValues = '';

  @ViewChild('search') search: ElementRef;

  public inputs: HTMLElement[] = [];
  public dropdowns: HTMLElement[] = [];
  public texts: HTMLElement[] = [];

  public originalData: any[] = [];

  @HostListener('change', ['$event'])
  onChange(event: any) {
    console.log('onChange', event, event.target.value);
    this.values &&
      this.values.sort(
        (firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!,
      );
    const element = this.elements?.find(
      (element: any) => element.id == event.target.value,
    );
    const index = this.values?.findIndex(
      (value: any) => value.id == element.id,
    );
    event.target.checked
      ? this.values!.push(element)
      : this.values!.splice(index!, 1);
    setTimeout(() => {
      this.getTextFromArray();
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      if (!this.isHidden) {
        this.onBlurEventEmitter.emit(event)
      }
    }
  }

  constructor(
    renderer: Renderer2,
    public utilsService: UtilsService,
    public literalService: LiteralService,
    private eRef: ElementRef
  ) {
    renderer.listen('window', 'click', (e: Event) => {
      //console.log(this.inputs, this.dropdowns, this.inputs[0] === null);
      if (this.inputs[0] !== null && this.dropdowns[0] !== null) {
        const close = utilsService.closeComponent(
          e,
          this.inputs,
          this.dropdowns,
          this.title,
        );
        if (close) {
          this.isHidden = true;
          this.searchableText = '';
          this.elements = this.originalData;
          this.getTextFromArray();
        } else {
          this.isHidden = false;
        }
      } else {
        const dropdownParent = document.getElementById(this.dropdownParent!);
        const clickInside = utilsService.openComponentOnlyWithDropdown(
          e,
          dropdownParent!,
          this.dropdowns,
          this.title,
        );
        if (this.showDropDown) {
          this.closeEventEmitter!.emit();
        } else if (clickInside) {
          this.openEventEmitter!.emit();
        }
      }
    });
    this.getTextFromArray();
  }

  ngOnInit(): void {
    this.originalData = this.elements!;
    this.getTextFromArray();
  }

  ngAfterViewInit(): void {
    this.inputs.push(document.getElementById('input-' + this.title)!);
    this.dropdowns.push(document.getElementById('dropdown-' + this.title)!);
  }

  public checked(item: any) {
    return this.values?.find((value) => value && value.id == item.id);
  }

  public filterElements() {
    if (this.searchableText.length > 0) {
      this.elements = this.originalData?.filter((value: any) =>
        value.code.toLowerCase().includes(this.searchableText.toLowerCase()),
      );
    } else {
      this.elements = this.originalData;
    }
  }

  getTextFromArray() {
    setTimeout(() => {
      this.textValues = '';
      this.elements &&
        this.elements.sort(
          (firstItem: IBase, secondItem: IBase) =>
            firstItem.id! - secondItem.id!,
        );
      this.elements &&
        this.elements.map((value: any) => {
          const isLast = value.id === this.elements?.at(-1)?.id;
          this.checked(value)
            ? (this.textValues +=
                (value.name || value.id || value.code) + (!isLast ? ', ' : ''))
            : null;
        });
      this.textValues === ''
        ? (this.textValues = '-')
        : this.textValues.at(-2) === ','
          ? (this.textValues = this.textValues.slice(0, -2))
          : null;
    });
  }
}
